import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import LayoutImages from "../components/image/LayoutImages"
import EditMenu from "../components/image/EditMenu"
import Profile from "../components/login/profile"
export default ({location}) => {
  return(
    <Layout>
      <SEO title="Page two" />
      <LayoutImages>
        <EditMenu />
        <Profile />
      </LayoutImages>    
    </Layout>
  )  
}


